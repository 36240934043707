import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FunctionsService, HttpRequestHandler, HttpRequestStream, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

import { TransportType } from '../transport';
import { ExtracurricularType } from '../extracurriculars';

export enum MailType {
  Transport = 1,
  General = 2,
  Extracurricular = 3,
}

export enum MailStatus {
  Created = 1,
  PartiallySent = 2,
  Sent = 3,
}

export enum GeneralMailType {
  MainAttendants = 1,
  CategoryAttendants = 2,
  CustomAttendants = 3,
  Students = 4,
  CustomStudents = 5,
  MiddlePeriodReports = 6,
}

@Injectable()
export class MailService {
  public static URL = environment.URL.API + 'mail';

  statuses = [
    { id: MailStatus.Created, name: '', key: 'general.created', class: 'label label-default' },
    { id: MailStatus.PartiallySent, name: '', key: 'mail.partiallySent', class: 'label label-primary' },
    { id: MailStatus.Sent, name: '', key: 'general.sent', class: 'label label-success' }
  ];

  transportTypes = [
    { id: TransportType.General, name: '', key: 'general.general' },
    { id: TransportType.Pickup, name: '', key: 'studentTransport.pickup' },
    { id: TransportType.Destination, name: '', key: 'studentTransport.destination' }
  ];

  extracurricularTypes = [
    { id: ExtracurricularType.Activity, name: '', key: 'extracurricularMail.types.activity' },
    { id: ExtracurricularType.Day, name: '', key: 'extracurricularMail.types.day' },
    { id: ExtracurricularType.Section, name: '', key: 'extracurricularMail.types.section' },
    { id: ExtracurricularType.Teacher, key: 'extracurricularMail.types.teacher', name: '' },
    { id: ExtracurricularType.Group, key: 'extracurricularMail.types.group', name: '' }
  ];

  generalTypes = [
    { id: GeneralMailType.MainAttendants, name: '', key: 'mail.general.mainAttendants' },
    { id: GeneralMailType.CategoryAttendants, name: '', key: 'mail.general.categoryAttendants' },
    { id: GeneralMailType.CustomAttendants, name: '', key: 'mail.general.customAttendants' },
    { id: GeneralMailType.Students, name: '', key: 'student.title' },
    { id: GeneralMailType.CustomStudents, name: '', key: 'mail.general.customStudents' },
    { id: GeneralMailType.MiddlePeriodReports, name: '', key: 'mail.general.middlePeriodReports' }
  ];

  /**
   * Empty constructor
   * @param http HttpService
   * @param fn FunctionsService
   */ constructor(private http: HttpService, translate: TranslateService, fn: FunctionsService) {
    const lists = {
      statuses: this.statuses,
      transportTypes: this.transportTypes,
      extracurricularTypes: this.extracurricularTypes,
      generalTypes: this.generalTypes
    };

    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  getStatus(status: MailStatus) {
    return this.statuses.find(s => s.id === status);
  }

  /**
   * List mails
   * @param type MailType
   * @param params string
   * @param httpRequestHandler HttpRequestHandler
   */
  index(type: MailType, params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${MailService.URL}/index/${type}?${params}`, httpRequestHandler);
  }

  /**
   * Get mail
   * @param id number
   * @param type MailType
   * @param httpRequestHandler HttpRequestHandler
   */
  get(id: number, type: MailType, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${MailService.URL}/${id}/${type}`, httpRequestHandler);
  }

  /**
   * Store mail
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${MailService.URL}/save`, data, httpRequestHandler);
  }

  /**
   * Mailing
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  mailing(id: number, opts: HttpRequestStream) {
    this.http.stream(`${MailService.URL}/${id}/mailing`, opts);
  }

  /**
   * Delete mail
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${MailService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Listado de estudiantes para envio de correos
   */
  students(yearId: number, params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${MailService.URL}/students/${yearId}?${params}`, httpRequestHandler);
  }

  /**
   * Datos base de envío de correos generales
   */
  baseInfo(httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${MailService.URL}/base-info`, httpRequestHandler);
  }

  /**
   * Listado de notificaciones de correo
   */
  notifications(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${MailService.URL}/${id}/notifications`, httpRequestHandler);
  }

  /**
   * Copiar correo
   */
  copy(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${MailService.URL}/${id}/copy`, null, httpRequestHandler);
  }
}
