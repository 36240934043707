import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class ExtracurricularTeacherAttendanceTypeService {
  public static URL = environment.URL.API2 + 'extracurricular-teacher-attendance-type';

  constructor(private http: HttpService) {
  }

  /**
   * List Extracurricular Teacher Attendance Types
   *
   * @param params string
   * @param httpRequestHandler HttpRequestHandler
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ExtracurricularTeacherAttendanceTypeService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Get Extracurricular Teacher Attendance Type
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ExtracurricularTeacherAttendanceTypeService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save Extracurricular Teacher Attendance Type
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(ExtracurricularTeacherAttendanceTypeService.URL, data, httpRequestHandler);
  }

  /**
   * Delete Extracurricular Teacher Attendance Type
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${ExtracurricularTeacherAttendanceTypeService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * List Extracurricular Teacher Attendance Types for select component
   */
  list = () => {
    return this.http.observableGet(
      `${ExtracurricularTeacherAttendanceTypeService.URL}/list`,
      { mapFn: res => res.extracurricularTeacherAttendanceTypes }
    );
  };
}
