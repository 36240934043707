import { Injectable } from '@angular/core';
import { FunctionsService, HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';
import { TranslateService } from '@ngx-translate/core';

export enum ExtracurricularType {
  Activity = 1,
  Day = 2,
  General = 3,
  Section = 4,
  Teacher = 5,
  Group = 6,
}

export enum ExtracurricularReportType {
  General = 1,
  Detailed = 2,
  Schedule = 3,
  Attendance = 4,
}

@Injectable()
export class StudentExtracurricularService {
  public static URL = environment.URL.API2 + 'student-extracurricular';

  extracurricularTypes = [
    { id: ExtracurricularType.Activity, key: 'extracurricularMail.types.activity', name: '' },
    { id: ExtracurricularType.Day, key: 'extracurricularMail.types.day', name: '' },
    { id: ExtracurricularType.Section, key: 'extracurricularMail.types.section', name: '' },
    { id: ExtracurricularType.Teacher, key: 'extracurricularMail.types.teacher', name: '' },
    { id: ExtracurricularType.Group, key: 'extracurricularMail.types.group', name: '' }
  ];

  extracurricularReportTypes = [
    { id: ExtracurricularReportType.General, key: 'general.general', name: '' },
    { id: ExtracurricularReportType.Detailed, key: 'general.detailed', name: '' },
    { id: ExtracurricularReportType.Schedule, key: 'general.schedule', name: '' },
    { id: ExtracurricularReportType.Attendance, key: 'general.attendance', name: '' }
  ];

  constructor(private http: HttpService, translate: TranslateService, fn: FunctionsService) {
    const lists = {
      extracurricularReportTypes: this.extracurricularReportTypes,
      extracurricularTypes: this.extracurricularTypes
    };

    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  /**
   * List of Students with Extracurriculars
   *
   * @param params string
   * @param httpRequestHandler HttpRequestHandler
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${StudentExtracurricularService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Get Student with Extracurriculars
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${StudentExtracurricularService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save Student with Extracurriculars
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(StudentExtracurricularService.URL, data, httpRequestHandler);
  }

  /**
   * Delete Students from Extracurriculars
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${StudentExtracurricularService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Create Extracurricular Plan in Portfolio.
   */
  billExtracurricularPlan(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${StudentExtracurricularService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Export Report for Students from Extracurriculars
   */
  export(data: any, httpRequestHandler: HttpRequestHandler) {
    return this.http.post(`${StudentExtracurricularService.URL}/export/`, data, httpRequestHandler);
  }

  /**
   * Get student plans
   * @param enrollmentId number
   * @param httpRequestHandler HttpRequestHandler
   */
  getPlans(enrollmentId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${StudentExtracurricularService.URL}/plans/${enrollmentId}`, httpRequestHandler);
  }

  /**
   * Get student schedule according grade
   * @param enrollmentId number
   * @param httpRequestHandler HttpRequestHandler
   */
  getSchedule(enrollmentId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${StudentExtracurricularService.URL}/schedule/${enrollmentId}`, httpRequestHandler);
  }

  getPlanInfo(planId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${StudentExtracurricularService.URL}/plan-info/${planId}`, httpRequestHandler);
  };

  /**
   * Save Student Extracurricular Plan
   */
  savePlan(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${StudentExtracurricularService.URL}/plan`, data, httpRequestHandler);
  }

  /**
   * Delete Student Extracurricular Plan
   */
  deletePlan(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${StudentExtracurricularService.URL}/delete-plan`, data, httpRequestHandler);
  }
}
