import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SelectLookupFn } from 'app/modules/shared/select';
import { environment } from 'environments/environment';

import { AutocompleteTypeahead, FunctionsService, HttpRequestHandler, HttpService } from '../../util';

export enum LibraryItemStatus {
  NotProcessed,
  PartiallySaved,
  Published,
}

export enum LibraryItemRangePrintType {
  Code = 1,
  Count = 2,
  Custom = 3,
  Quantity = 4,
}

export enum LibraryItemPrintType {
  Label = 1,
  Barcode = 2,
}

@Injectable()
export class LibraryItemService {
  public static URL = environment.URL.API + 'library-item';
  public static URL2 = environment.URL.API2 + 'library-item';

  itemStatuses = [
    { id: LibraryItemStatus.NotProcessed, name: '', key: 'general.notProcessed' },
    { id: LibraryItemStatus.PartiallySaved, name: '', key: 'general.savedTemporarily' },
    { id: LibraryItemStatus.Published, name: '', key: 'general.published' }
  ];

  rangePrintTypes = [
    { id: LibraryItemRangePrintType.Code, name: '', key: 'libraryItem.range.code' },
    { id: LibraryItemRangePrintType.Count, name: '', key: 'libraryItem.range.count' },
    { id: LibraryItemRangePrintType.Custom, name: '', key: 'libraryItem.range.custom' },
    { id: LibraryItemRangePrintType.Quantity, name: '', key: 'libraryItem.range.quantity' }
  ];

  printTypes = [
    { id: LibraryItemPrintType.Label, name: '', key: 'libraryItem.printing.label' },
    { id: LibraryItemPrintType.Barcode, name: '', key: 'libraryItem.printing.barcode' }
  ];

  /**
   * Empty constructor
   */
  constructor(private http: HttpService, fn: FunctionsService, translate: TranslateService) {
    const lists = {
      itemStatuses: this.itemStatuses,
      rangePrintTypes: this.rangePrintTypes,
      printTypes: this.printTypes
    };

    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  /**
   * List library items
   *
   * @param params string
   * @param httpRequestHandler HttpRequestHandler
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(LibraryItemService.URL2 + '?' + params, httpRequestHandler);
  }

  /**
   * Store library item
   */
  store(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(LibraryItemService.URL2, data, httpRequestHandler);
  }

  /**
   * Delete library item
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${LibraryItemService.URL2}/${id}`, httpRequestHandler);
  }

  /**
   * Get library item
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${LibraryItemService.URL2}/${id}`, httpRequestHandler);
  }

  /**
   * Label printing
   */
  labelPrinting(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${LibraryItemService.URL}/label-printing`, data, httpRequestHandler);
  }

  /**
   * Select autocomplete item
   */
  list: SelectLookupFn = data => {
    return this.http.observablePost(LibraryItemService.URL2 + '/list', data, { mapFn: res => res.items });
  };

  /**
   * Method for autocomplete fields
   */
  listFilter: AutocompleteTypeahead = (query, data) => {
    return this.http.observablePost(LibraryItemService.URL2 + '/list-indexes', { q: query, indexId: data }, { mapFn: res => res.indexes });
  };
}
