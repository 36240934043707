import { Injectable } from '@angular/core';
import { FunctionsService, HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';
import { AttendanceClassType } from '../academic';
import { TranslateService } from '@ngx-translate/core';

export enum ExtracurricularStudentAttendanceType {
  Present = null,
  Nonattendance = 1,
  Delay = 2,
}

@Injectable()
export class ExtracurricularStudentAttendanceService {
  public static URL = environment.URL.API2 + 'extracurricular-student-attendance';

  types = [
    { id: ExtracurricularStudentAttendanceType.Present, key: 'attendanceClass.presentWithObs', name: '' },
    { id: ExtracurricularStudentAttendanceType.Nonattendance, key: 'attendanceClass.nonattendance', name: '' },
    { id: ExtracurricularStudentAttendanceType.Delay, key: 'attendanceClass.delay', name: '' }
  ];

  constructor(private http: HttpService, translate: TranslateService, fn: FunctionsService) {
    const lists = { types: this.types };

    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  getType(type: ExtracurricularStudentAttendanceType) {
    return this.types.find(t => t.id === type);
  }

  /**
   * List groups
   */
  getGroups(yearId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ExtracurricularStudentAttendanceService.URL}/${yearId}/groups`, httpRequestHandler);
  }

  /**
   * List students
   */
  getStudents(groupId: number, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${ExtracurricularStudentAttendanceService.URL}/${groupId}/students`, httpRequestHandler);
  }

  /**
   * Get data
   */
  getData(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${ExtracurricularStudentAttendanceService.URL}/data`, data, httpRequestHandler);
  }

  /**
   * Set attendance class to student
   */
  setAttendanceClass(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${ExtracurricularStudentAttendanceService.URL}/set-attendance-class`, data, httpRequestHandler);
  }

  /**
   * Save attendance class observation
   */
  saveObservation(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${ExtracurricularStudentAttendanceService.URL}/save-observation`, data, httpRequestHandler);
  }

  /**
   * Grades list validating user secctions
   */
  grades(yearId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ExtracurricularStudentAttendanceService.URL}/grades/${yearId}`, httpRequestHandler);
  }

  /**
   * Take attendance
   */
  takeAttendance(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${ExtracurricularStudentAttendanceService.URL}/take-attendance`, data, httpRequestHandler);
  }

  /**
   * Export attendances
   */
  exportAttendances(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ExtracurricularStudentAttendanceService.URL}/general/export?${params}`, httpRequestHandler);
  }

  /**
   * Class grade group list
   */
  saveJustified(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${ExtracurricularStudentAttendanceService.URL}/save-justified`, data, httpRequestHandler);
  }
}
