import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FunctionsService, HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

import { YearContractType } from './year-contract.service';

export enum EnrollmentReportType {
  Discount = 1,
  DiscountWithPaymentOption = 2,
  GenericDiscount = 3,
  Concept = 4,
  Stamp = 5,
  Books = 6,
  BooksAnnex = 7,
  PendingDocuments = 8,
}

@Injectable()
export class EnrollReportsService {
  public static URL = environment.URL.API + 'enroll-reports';

  enrollmentReportTypes = [
    { id: EnrollmentReportType.Discount, key: 'enroll.report.discount', name: '', external: false },
    { id: EnrollmentReportType.GenericDiscount, key: 'enroll.report.genericDiscount', name: '', external: false },
    { id: EnrollmentReportType.DiscountWithPaymentOption, key: 'enroll.report.discountWithPaymentOption', name: '', external: false },
    { id: EnrollmentReportType.Concept, key: 'enroll.report.concept', name: '', external: true },
    { id: EnrollmentReportType.Stamp, key: 'enroll.report.stamp', name: '', external: false },
    { id: EnrollmentReportType.Books, key: 'enroll.report.enrollmentsBook', name: '', external: false, permission: 'enrollments_book_report' },
    {
      id: EnrollmentReportType.BooksAnnex,
      key: 'enroll.report.enrollmentsBookAnnex',
      name: '',
      external: false,
      permission: 'enrollments_book_report'
    },
    {
      id: EnrollmentReportType.PendingDocuments,
      key: 'enroll.report.pendingDocuments',
      name: '',
      external: false
    }
  ];

  constructor(private http: HttpService, private fn: FunctionsService, translate: TranslateService) {
    const lists = { enrollmentReportTypes: this.enrollmentReportTypes };

    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  /**
   * Descargar contrato (matrícula o transporte)
   * @param studentId
   * @param yearId
   * @param type
   * @param httpRequestHandler
   */
  downloadContract(studentId: number, yearId: number, type: YearContractType, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${EnrollReportsService.URL}/download-contract/${studentId}/${yearId}/${type}`, httpRequestHandler);
  }

  /**
   * Descargar recibo de pago (matrícula o transporte)
   * @param studentId
   * @param yearId
   * @param transport
   * @param httpRequestHandler
   */
  downloadReceipt(studentId: number, yearId: number, transport: number, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${EnrollReportsService.URL}/download-receipt/${studentId}/${yearId}/${transport}`, httpRequestHandler);
  }

  /**
   * Descargar liquidación de matrícula o transporte
   * @deprecated
   * @param studentId
   * @param yearId
   * @param transport (0 = false, 1 = true)
   * @param httpRequestHandler
   */
  downloadSettlementLegacy(studentId: number, yearId: number, transport: number, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${EnrollReportsService.URL}/download-settlement/${studentId}/${yearId}/${transport}`, httpRequestHandler);
  }

  /**
   * Descargar registro de matrícula
   * @param studentId
   * @param yearId
   * @param httpRequestHandler
   */
  downloadEnrollmentRecord(studentId: number, yearId: number, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${EnrollReportsService.URL}/download-enrollment-record/${studentId}/${yearId}`, httpRequestHandler);
  }

  /**
   * Descargar registro de matrícula
   * @param studentId
   * @param yearId
   * @param httpRequestHandler
   */
  downloadStudentRecord(studentId: number, yearId: number, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${EnrollReportsService.URL}/download-student-record/${studentId}/${yearId}`, httpRequestHandler);
  }

  /**
   * Descargar listado de documentos para matrícula
   * @param enrollmentId
   * @param httpRequestHandler
   */
  donwloadEnrollmentDocumentsList(studentId: number, yearId: number, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${EnrollReportsService.URL}/download-documents-list/${studentId}/${yearId}`, httpRequestHandler);
  }

  /**
   * Descargar registro de matrícula
   * @param enrollmentId
   * @param httpRequestHandler
   */
  downloadCertificate(enrollmentId: number, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${EnrollReportsService.URL}/download-certificate/${enrollmentId}`, httpRequestHandler);
  }

  /**
   * Generate report
   * @param value any
   * @param httpRequestHandler HttpRequestHandler
   */
  generateReport(value: any, httpRequestHandler: HttpRequestHandler) {
    return this.http.post(`${EnrollReportsService.URL}/reports`, value, httpRequestHandler);
  }

  /**
   * Descargar estado de cuenta
   * @param enrollmentId
   * @param httpRequestHandler
   */
  downloadStatement(enrollmentId: number, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${EnrollReportsService.URL}/download-statement/${enrollmentId}`, httpRequestHandler);
  }

  /**
   * Get enrollment report concepts
   */
  enrollmentReportConcepts = data => {
    return this.http.observablePost(`${EnrollReportsService.URL}/enrollment-concepts`, data, { mapFn: res => res.concepts });
  };
}
