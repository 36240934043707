import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';
import { SelectLookupFn } from 'app/modules/shared/select';

@Injectable()
export class ExtracurricularTeacherService {
  public static URL = environment.URL.API2 + 'extracurricular-teacher';

  constructor(private http: HttpService) {
  }

  /**
   * List Extracurricular Activities
   *
   * @param params string
   * @param httpRequestHandler HttpRequestHandler
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ExtracurricularTeacherService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Get Extracurricular Activity
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ExtracurricularTeacherService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save Extracurricular Activity
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(ExtracurricularTeacherService.URL, data, httpRequestHandler);
  }

  /**
   * Delete Extracurricular Activity
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${ExtracurricularTeacherService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * List Extracurricular Activities for select component
   */
  list = () => {
    return this.http.observableGet(`${ExtracurricularTeacherService.URL}/list`, { mapFn: res => res.teachers });
  };

  /**
   * List Extracurricular Activities from year
   */
  fromYear = data => {
    return this.http.observableGet(`${ExtracurricularTeacherService.URL}/list/year/${data.yearId}`, { mapFn: res => res.teachers });
  };

  /**
   * Select autocomplete extracurricular teachers
   */
  listLookup: SelectLookupFn = data => {
    return this.http.observablePost(`${ExtracurricularTeacherService.URL}/list-lookup`, data, { mapFn: res => res.data });
  };
}
