import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { StudentExtracurricularService } from '../extracurriculars';

@Injectable()
export class ExtracurricularTransportService {
  public static URL = environment.URL.API2 + 'extracurricular-transport';

  constructor(private http: HttpService) {
  }

  /**
   * Index extracurricular transport
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ExtracurricularTransportService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Get extracurricular transport
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ExtracurricularTransportService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save extracurricular transport
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${StudentExtracurricularService.URL}/save-transport`, data, httpRequestHandler);
  }

  /**
   * Delete extracurricular transport
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${ExtracurricularTransportService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * List extracurricular transport for select component
   */
  list = () => {
    return this.http.observableGet(`${ExtracurricularTransportService.URL}/list`, { mapFn: res => res.extracurricularTransport });
  };
}
