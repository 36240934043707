<div
  id="pcoded"
  (window:resize)="onResize($event)"
  class="pcoded iscollapsed"
  theme-layout="vertical"
  vertical-placement="left"
  [attr.vertical-layout]="verticalLayout"
  [attr.pcoded-device-type]="deviceType"
  [attr.vertical-nav-type]="verticalNavType"
  [attr.vertical-effect]="verticalEffect"
  vnavigation-view="view1"
>
  <div class="pcoded-overlay-box"></div>

  <div class="pcoded-container navbar-wrapper z-depth-0">
    <nav class="navbar header-navbar pcoded-header iscollapsed" header-theme="theme4" [attr.pcoded-header-position]="navPcodedHeaderPosition">
      <div class="navbar-wrapper">
        <div class="navbar-logo" navbar-theme="theme4" [ngClass]="{ 'no-sidebar': !sessionService.sidebarMenu, 'sidebar-testing': env.testing }">
          <a
            class="mobile-menu"
            id="mobile-collapse"
            href="javascript:;"
            (click)="toggleOpened()"
            [exclude]="'#main_navbar'"
            (clickOutside)="onClickedOutside($event)"
            *ngIf="sessionService.sidebarMenu"
          >
            <i class="ti-menu"></i>
          </a>
          <a [routerLink]="['/']">
            <img class="img-fluid" src="./assets/img/bennett/navbar-logo.png" alt="Colegio Bennett" title="Colegio Bennett"/>
          </a>
          <a (click)="onMobileMenu()" class="mobile-options"> <i class="ti-more"></i> </a>
        </div>

        <div class="navbar-container">
          <div>
            <ul class="nav-left">
              <li *ngIf="sessionService.sidebarMenu">
                <div class="sidebar_toggle">
                  <a href="javascript:;" (click)="toggleOpened()"> <i class="ti-menu f-18"></i> </a>
                </div>
              </li>
              <li class="hover-bg-light">
                <a href="javascript:;" appToggleFullscreen> <i class="ti-fullscreen"></i> </a>
              </li>

              <li>
                <div class="d-none d-xl-block" style="width: 380px; line-height: initial; margin-top: 11px;">
                  <app-select
                    [options]="sessionService.actions | async"
                    class="default menu-select"
                    (itemChange)="actionChange($event)"
                    [(ngModel)]="actionId"
                    searchField="transName"
                    placeholder="general.search"
                    groupBy="transMenuName"
                  >
                    <ng-template selectOption let-option>
                      <div class="d-flex">
                        <div
                          class="f-26 mr-1 p-1 justify-content-center rounded-pill d-flex bg-{{ option.color | bgColor }}"
                          style="width: 40px; min-width: 40px; height: 40px;"
                        >
                          <i class="align-self-center m-0" style="margin: 0 2px 0 0 !important;" [ngClass]="option.icon"></i>
                        </div>
                        <div class="flex-fill align-self-center overflow-hidden">
                          <span class="text-uppercase f-w-600">{{ option.transName }}</span>
                          <br/>
                          <small>
                            <span class="text-default">{{ option.desc ? (option.desc | translate) : '--' }}</span>
                          </small>
                        </div>
                      </div>
                    </ng-template>
                  </app-select>
                </div>
              </li>

              <li *ngIf="env.testing" class="text-default f-w-500 f-16 my-n1">AMBIENTE DE PRUEBAS</li>
            </ul>
            <ul [@mobileMenuTop]="isCollapsedMobile" class="nav-right" [ngClass]="isCollapsedMobile">
              <li class="header-notification lng-dropdown">
                <a href="javascript:;" id="dropdown-active-item">
                  <img src="./assets/img/icons/{{ language.flagIcon }}" alt="" class="mr-1 mt-n1"/> {{ language.name }}
                </a>
                <ul class="show-notification">
                  <li *ngFor="let item of languages">
                    <a href="javascript:;" (click)="changeLang(item)">
                      <img src="./assets/img/icons/{{ item.flagIcon }}" alt="" class="flag-language mt-n1"/> {{ item.name }}
                      <i class="fa fa-check m-l-5" *ngIf="translate.currentLang === item.lang"></i>
                    </a>
                  </li>
                </ul>
              </li>

              <li class="header-notification nav-notifications pl-3 pr-3" *ngIf="sessionService.sidebarMenu">
                <a href="javascript:;">
                  <i class="fa fa-bell-o fa-lg"></i>
                  <span class="badge" *ngIf="sessionService.unseenCounter">{{ sessionService.unseenCounter }}</span>
                </a>
                <ul class="show-notification" [hidden]="notificationsListHidden">
                  <li class="border-bottom no-hover-bg">
                    <h6>{{ 'notification.title' | translate }} <i class="fa fa-spinner fa-pulse ml-1" *ngIf="notifications.loading"></i></h6>
                  </li>
                  <li class="p-0 no-hover-bg">
                    <div
                      style="max-height: 400px; overflow-y: scroll;"
                      infiniteScroll
                      (scrolled)="notificationScroll()"
                      [infiniteScrollDistance]="3"
                      [infiniteScrollThrottle]="50"
                      [scrollWindow]="false"
                      slimScroll
                      height="100%"
                      size="6px"
                      [alwaysVisible]="true"
                    >
                      <ul>
                        <li
                          *ngFor="let item of sessionService.notifications.list"
                          class="pt-1 pr-3 pb-1 pl-3 cursor-pointer border-top"
                          [ngClass]="{ unseen: !item.seen }"
                          (click)="notificationClick(item)"
                        >
                          <div class="media">
                            <i class="d-flex fa-3x mr-3" [ngClass]="item.moduleIcon"></i>
                            <div class="media-body pt-1">
                              <p class="notification-msg">{{ item.content | translate }}</p>
                              <span class="notification-time">
                                <i class="mr-1" [ngClass]="item.actionIcon"></i>
                                <span class="text-muted">{{ item.createdAt | amLocale: translate.currentLang | amTimeAgo }}</span>
                              </span>
                              <i
                                class="fa fa-eye float-right fa-lg mark-as-seen"
                                placement="left"
                                [ngbTooltip]="'notification.markAsSeen' | translate"
                                (click)="markAsSeenClick($event, item)"
                                *ngIf="!item.seen"
                              ></i>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <!-- <li class="cursor-pointer text-center p-2 border-top" (click)="viewAllNotifications()">
                    <h6 class="mb-0 text-primary">{{ 'notification.viewAll' | translate }}</h6>
                  </li> -->
                </ul>
              </li>

              <ng-container *ngIf="loading.user | async; else loadaedUserTpl">
                <li class="user-profile header-notification" style="line-height: 0;">
                  <div class="d-inline-block mt-2">
                    <div class="ph-item placeholder-user">
                      <div class="ph-col-2 p-0">
                        <div class="ph-avatar"></div>
                      </div>
                      <div class="p-0 mt-2 pl-2">
                        <div class="ph-row m-0">
                          <div class="ph-col-12 big m-0"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ng-container>
              <ng-template #loadaedUserTpl>
                <li class="user-profile header-notification">
                  <a href="javascript:;">
                    <div class="d-inline-block m-r-10" style="margin-top: 8px;">
                      <ngx-avatar [value]="sessionService.user?.initials" [size]="40" [textSizeRatio]="1.7"></ngx-avatar>
                    </div>
                    <div class="d-inline-block nav-username">
                      <span>{{ sessionService.user?.fullName }}</span> <i class="ti-angle-down"></i>
                    </div>
                  </a>
                  <ul class="show-notification profile-notification">
                    <ng-template [ngIf]="sessionService.sidebarMenu">
                      <li>
                        <a href="/human-talent/my-profile" class="cursor-pointer">
                          <i class="icofont icofont-user-alt-2"></i> {{ 'myProfile.title' | translate }}
                        </a>
                      </li>
                      <li>
                        <a (click)="openChangePassword()" class="cursor-pointer">
                          <i class="fa fa-lock"></i> {{ 'auth.changePassword' | translate }}
                        </a>
                      </li>
                      <!--<li>
                        <a (click)="myPermissions()" class="cursor-pointer">
                          <i class="fa fa-address-card-o"></i> {{ 'permission.mine' | translate }}
                        </a>
                      </li>-->
                    </ng-template>
                  </ul>
                </li>
              </ng-template>
              <li class="nav-logout pl-3 pr-3 hover-bg-light" *ngIf="sessionService.sidebarMenu">
                <a href="javascript:;" (click)="logout()">
                  <i class="fa fa-sign-out fa-lg"></i> {{ 'general.logout' | translate }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>

    <div class="pcoded-main-container" [ngStyle]="pcodedMainContainerStyle">
      <div class="pcoded-wrapper">
        <nav
          id="main_navbar"
          class="pcoded-navbar"
          (clickOutside)="onClickedOutside($event)"
          [exclude]="'#mobile-collapse'"
          navbar-theme="theme4"
          active-item-theme="theme4"
          sub-item-theme="theme2"
          active-item-style="style0"
          [attr.pcoded-navbar-position]="sidebarPcodedNavbarPosition"
          *ngIf="sessionService.sidebarMenu"
        >
          <div class="sidebar_toggle">
            <a href="javascript:;"> <i class="icon-close icons"></i> </a>
          </div>

          <div
            class="pcoded-inner-navbar main-menu"
            [ngClass]="{ 'sidebar-testing': env.testing }"
            appAccordion
            slimScroll
            width="100%"
            height="100%"
            size="4px"
            color="#fff"
            opacity="0.3"
            allowPageScroll="false"
          >
            <div>
              <div class="mt-2">
                <ul class="pcoded-item pcoded-left-item pcoded-p2p">
                  <li>
                    <a>
                      <span class="pcoded-micon custom-pcoded-micon"> </span>
                      <span class="pcoded-mtext w-100">
                        <div class="d-table">
                          <div class="d-table-cell w-100">
                            <a href="https://www.placetopay.com" target="_blank">
                              <img src="https://static.placetopay.com/placetopay-logo-dark-background.svg" class="p2p-full-logo"/>
                            </a>
                          </div>
                          <div class="d-table-cell v-middle pr-3">
                            <a class="d-block pt-1 text-white" [routerLink]="['/', 'place-to-pay-faq']">
                              <span>FAQ</span>
                            </a>
                          </div>
                        </div>
                      </span>
                    </a>
                  </li>
                </ul>
              </div>

              <ng-container *ngIf="(loading.menu | async) || mainMenu == null; else menuLoadedTpl">
                <div class="ph-item placeholder-menu p-0 mt-3">
                  <div>
                    <div class="ph-row">
                      <ng-container *ngFor="let n of [1, 2, 3]">
                        <div class="big ph-col-8"></div>
                        <div class="big ph-col-4 empty"></div>
                        <div class="big ph-col-12"></div>
                        <div class="big ph-col-6"></div>
                        <div class="big ph-col-6 empty"></div>
                        <div class="big ph-col-10"></div>
                        <div class="big ph-col-2 empty"></div>
                        <div class="big ph-col-9"></div>
                        <div class="big ph-col-1 empty"></div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-template #menuLoadedTpl>
                <div *ngFor="let asideItems of mainMenu">
                  <div class="pcoded-navigatio-lavel" menu-title-theme="theme5">{{ asideItems.name | translate }}</div>

                  <ul
                    class="pcoded-item pcoded-left-item"
                    item-border="none"
                    item-border-style="solid"
                    subitem-border="solid"
                    *ngFor="let asideItem of asideItems.children"
                    appAccordionLink
                    group="{{ asideItem.state }}"
                  >
                    <li [routerLinkActive]="['active']" *ngIf="asideItem.type === 'external'" appAccordionLink group="{{ asideItem.state }}">
                      <a href="{{ asideItem.external }}" target="{{ asideItem.target ? '_blank' : '_self' }}" appAccordionToggle>
                        <span class="pcoded-micon"> <i class="{{ asideItem.icon }}"></i> </span>
                        <span class="pcoded-mtext">{{ asideItem.name | translate }}</span>
                        <span *ngFor="let asideBadge of asideItem.badge" class="pcoded-badge label label-{{ asideBadge.type }}">{{
                            asideBadge.value
                          }}</span>
                        <span class="pcoded-mcaret"></span>
                      </a>
                    </li>

                    <li [routerLinkActive]="['active']" *ngIf="asideItem.type === 'link'" appAccordionLink group="{{ asideItem.state }}">
                      <a
                        [routerLink]="['/', asideItem.main_state, asideItem.state]"
                        target="{{ asideItem.target ? '_blank' : '_self' }}"
                        appAccordionToggle
                        *ngIf="asideItem.main_state; else mainContent"
                      >
                        <span class="pcoded-micon"> <i class="{{ asideItem.icon }}"></i> </span>
                        <span class="pcoded-mtext">{{ asideItem.name | translate }}</span>
                        <span *ngFor="let asideBadge of asideItem.badge" class="pcoded-badge label label-{{ asideBadge.type }}">{{
                            asideBadge.value
                          }}</span>
                        <span class="pcoded-mcaret"></span>
                      </a>
                      <ng-template #mainContent>
                        <a [routerLink]="('/' + asideItem.state).split('/')" target="{{ asideItem.target ? '_blank' : '_self' }}" appAccordionToggle>
                          <span class="pcoded-micon"> <i class="{{ asideItem.icon }}"></i> </span>
                          <span class="pcoded-mtext">{{ asideItem.name | translate }}</span>
                          <span *ngFor="let asideBadge of asideItem.badge" class="pcoded-badge label label-{{ asideBadge.type }}">{{
                              asideBadge.value
                            }}</span>
                          <span class="pcoded-mcaret"></span>
                        </a>
                      </ng-template>
                    </li>

                    <li
                      [routerLinkActive]="['active']"
                      *ngIf="asideItem.type === 'sub'"
                      class="pcoded-hasmenu"
                      dropdown-icon="style1"
                      subitem-icon="style6"
                      appAccordionLink
                      group="{{ asideItem.state }}"
                    >
                      <a [routerLinkActive]="['active']" href="javascript:;" appAccordionToggle>
                        <span class="pcoded-micon"> <i class="{{ asideItem.icon }}"></i> </span>
                        <span class="pcoded-mtext">{{ asideItem.name | translate }}</span>
                        <span *ngFor="let asideBadge of asideItem.badge" class="pcoded-badge label label-{{ asideBadge.type }}">{{
                            asideBadge.value
                          }}</span>
                        <span class="pcoded-mcaret"></span>
                      </a>
                      <ul class="pcoded-submenu">
                        <ng-template ngFor let-asideChildren [ngForOf]="asideItem.children">
                          <li [routerLinkActive]="['active']" *ngIf="asideChildren.type !== 'sub'">
                            <a
                              [routerLink]="('/' + (asideItem.state ? asideItem.state + '/' : '') + asideChildren.state).split('/')"
                              target="{{ asideChildren.target ? '_blank' : '_self' }}"
                            >
                              <span class="pcoded-micon"> <i class="ti-angle-right"></i> </span>
                              <span class="pcoded-mtext">{{ asideChildren.name | translate }}</span>
                              <span
                                *ngFor="let asideChildrenBadge of asideChildren.badge"
                                class="pcoded-badge label label-{{ asideChildrenBadge.type }}"
                              >{{ asideChildrenBadge.value }}</span
                              >
                              <span class="pcoded-mcaret"></span>
                            </a>
                          </li>

                          <li
                            [routerLinkActive]="['active']"
                            class="pcoded-hasmenu"
                            dropdown-icon="style1"
                            subitem-icon="style6"
                            *ngIf="asideChildren.type === 'sub'"
                            appAccordionLink
                            group="sub-toggled"
                          >
                            <a href="javascript:;" appAccordionToggle>
                              <span class="pcoded-micon"> <i class="ti-direction-alt"></i> </span>
                              <span class="pcoded-mtext">{{ asideChildren.name | translate }}</span>
                              <span
                                *ngFor="let asideChildrenBadge of asideChildren.badge"
                                class="pcoded-badge label label-{{ asideChildrenBadge.type }}"
                              >{{ asideChildrenBadge.value }}</span
                              >
                              <span class="pcoded-mcaret"></span>
                            </a>
                            <ul class="pcoded-submenu">
                              <ng-template ngFor let-asideChildrenSub [ngForOf]="asideChildren.children">
                                <li [routerLinkActive]="['active']">
                                  <a
                                    [routerLink]="
                                      (
                                        '/' +
                                        (asideItem.state ? asideItem.state + '/' : '') +
                                        (asideChildren.state ? asideChildren.state + '/' : '') +
                                        asideChildrenSub.state
                                      ).split('/')
                                    "
                                    target="{{ asideChildrenSub.target ? '_blank' : '_self' }}"
                                  >
                                    <span class="pcoded-micon"> <i class="ti-angle-right"></i> </span>
                                    <span class="pcoded-mtext">{{ asideChildrenSub.name | translate }}</span>
                                    <span
                                      *ngFor="let asideChildrenSubBadge of asideChildrenSub.badge"
                                      class="pcoded-badge label label-{{ asideChildrenSubBadge.type }}"
                                    >{{ asideChildrenSubBadge.value }}</span
                                    >
                                    <span class="pcoded-mcaret"></span>
                                  </a>
                                </li>
                              </ng-template>
                            </ul>
                          </li>
                        </ng-template>
                      </ul>
                    </li>
                  </ul>
                </div>
              </ng-template>
            </div>
          </div>
        </nav>

        <div class="pcoded-content" [ngClass]="{ 'ml-0': !sessionService.sidebarMenu }">
          <div class="pcoded-inner-content">
            <div class="main-body">
              <div class="page-wrapper">
                <app-title></app-title>
                <app-breadcrumbs></app-breadcrumbs>
                <div class="page-body">
                  <router-outlet>
                    <spinner></spinner>
                  </router-outlet>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<simple-notifications [options]="notify.options"></simple-notifications>

<app-modal [title]="'permission.mine'" [size]="ModalSize.Large" (closed)="myPermissionsModalClosed()" #myPermissionsModal>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <strong class="f-16 p-1">{{ 'role.title' | translate }}:</strong>
        <span *ngFor="let role of myData.roles" class="label label-info">{{ role.displayName }}</span>
        <hr class="mt-2 mb-1"/>
        <h6 class="f-16 p-1 mb-1">
          <strong>{{ 'permission.title' | translate }}</strong>
        </h6>

        <ngx-datatable [appLocalDt]="myData.permissions">
          <ngx-datatable-column [resizeable]="false" [flexGrow]="2" prop="name" name="permission.name">
            <ng-template let-col="column" let-sort="sortFn" ngx-datatable-header-template>
              <app-datatable-header [col]="col" [sort]="sort"></app-datatable-header>
              <app-local-input-column-filter [localDt]="myData.permissions" [col]="col"></app-local-input-column-filter>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column [resizeable]="false" [flexGrow]="3" prop="displayName" name="permission.displayName">
            <ng-template let-col="column" let-sort="sortFn" ngx-datatable-header-template>
              <app-datatable-header [col]="col" [sort]="sort"></app-datatable-header>
              <app-local-input-column-filter [localDt]="myData.permissions" [col]="col"></app-local-input-column-filter>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </div>
    </div>
  </div>
</app-modal>

<app-modal [title]="'auth.changePassword'" [closable]="!changePassword.required" [size]="ModalSize.Normal" #changePasswordModal>
  <app-dimmer [loading]="changePassword.loading"></app-dimmer>
  <div class="modal-body">
    <form [formGroup]="changePassword.form" (submit)="submitChangePassword()" id="changePasswordForm">
      <div class="alert alert-info icons-alert mb-3" *ngIf="changePassword.required">
        <p class="p-0">{{ 'auth.passwordChangeRequired' | translate }}</p>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label>{{ 'auth.currentPassword' | translate }}</label>
            <input type="password" class="form-control form-control-sm" formControlName="current"/>
            <app-form-control-error formControlName="current"></app-form-control-error>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <div class="d-inline-flex">
              <label>{{ 'auth.newPassword' | translate }}</label>
            </div>
            <input type="password" class="form-control form-control-sm" formControlName="new"/>
            <!--TODO; get better password stregth validation-->
            <!--            <password-strength-meter [password]="changePassword.form.controls['new'].value"></password-strength-meter>-->
            <app-form-control-error formControlName="new"></app-form-control-error>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label>{{ 'auth.newPasswordConfirmation' | translate }}</label>
            <input type="password" class="form-control form-control-sm" formControlName="confirmation"/>
            <app-form-control-error formControlName="confirmation"></app-form-control-error>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <button type="submit" class="btn btn-sm btn-primary" form="changePasswordForm" [disabled]="changePassword.loading">
      {{ 'general.confirm' | translate }}
    </button>
    <button type="button" (click)="changePasswordModal.close()" class="btn btn-default btn-sm" *ngIf="!changePassword.required">
      {{ 'general.cancel' | translate }}
    </button>
  </div>
</app-modal>
