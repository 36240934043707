import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { NgbDateStruct, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable } from 'rxjs';

import { NotifyMessageService } from './notify-message.service';

export enum Mod {
  Create = 1,
  Edit = 2,
  View = 3,
  Delete = 4,
}

export enum ImageEnum {
  Attendant = 'attendant',
  Student = 'student',
  General = 'general',
  Place = 'place',
}

export enum ReceiptDeductionType {
  PaymentAgreement = 1,
  Prepaid = 2,
}

export enum ReceiptStatus {
  Cancelled = 0,
  Created = 1,
  Paid = 2,
}

export declare type AutocompleteTypeahead = (query: string, data?: any) => Observable<any>;

export interface ITranslateString {
  key: string;
  params: any;
}

export enum DaysOfWeek {
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
  Sunday = 7,
}

export enum Month {
  January = 1,
  February = 2,
  March = 3,
  April = 4,
  May = 5,
  June = 6,
  July = 7,
  August = 8,
  September = 9,
  October = 10,
  November = 11,
  December = 12,
}

export enum RuleType {
  General = 1,
  Section = 2,
  Grade = 3,
}

export enum BooleanType {
  Yes = 1,
  No = 0,
}

export interface IFileData {
  name: string;
  mime: string;
  size: number;
  ext: string;
  base64: string;
}

export enum ReceiptType {
  Enrollment = 1,
  EnrollmentTransport = 2,
  Partial = 3,
}

export interface IDtMessages {
  emptyMessage: string;
  totalMessage: string;
}

@Injectable()
export class FunctionsService {
  public systemStartYear = 2018;
  public firstGraduation = 1974;

  dtMessages: IDtMessages = {
    emptyMessage: '',
    totalMessage: ''
  };

  public days = [
    { id: DaysOfWeek.Monday, name: '', initial: '', key: 'days.monday', field: 'monday', weekend: false },
    { id: DaysOfWeek.Tuesday, name: '', initial: '', key: 'days.tuesday', field: 'tuesday', weekend: false },
    { id: DaysOfWeek.Wednesday, name: '', initial: '', key: 'days.wednesday', field: 'wednesday', weekend: false },
    { id: DaysOfWeek.Thursday, name: '', initial: '', key: 'days.thursday', field: 'thursday', weekend: false },
    { id: DaysOfWeek.Friday, name: '', initial: '', key: 'days.friday', field: 'friday', weekend: false },
    { id: DaysOfWeek.Saturday, name: '', initial: '', key: 'days.saturday', field: 'saturday', weekend: true },
    { id: DaysOfWeek.Sunday, name: '', initial: '', key: 'days.sunday', field: 'sunday', weekend: true }
  ];

  public months = [
    { id: Month.January, name: '', key: 'months.january' },
    { id: Month.February, name: '', key: 'months.february' },
    { id: Month.March, name: '', key: 'months.march' },
    { id: Month.April, name: '', key: 'months.april' },
    { id: Month.May, name: '', key: 'months.may' },
    { id: Month.June, name: '', key: 'months.june' },
    { id: Month.July, name: '', key: 'months.july' },
    { id: Month.August, name: '', key: 'months.august' },
    { id: Month.September, name: '', key: 'months.september' },
    { id: Month.October, name: '', key: 'months.october' },
    { id: Month.November, name: '', key: 'months.november' },
    { id: Month.December, name: '', key: 'months.december' }
  ];

  public ruleTypes = [
    { id: RuleType.General, name: '', key: 'general.general' },
    { id: RuleType.Section, name: '', key: 'section.msgTitle' },
    { id: RuleType.Grade, name: '', key: 'grade.msgTitle' }
  ];

  public receiptDeductionsTypes = [
    { id: ReceiptDeductionType.PaymentAgreement, name: '', key: 'paymentAgreement.msgTitle' },
    { id: ReceiptDeductionType.Prepaid, name: '', key: 'general.prepaid' }
  ];

  public receiptTypes = [
    { id: ReceiptType.Enrollment, name: '', key: 'paymentUpload.receiptType.enrollment' },
    { id: ReceiptType.EnrollmentTransport, name: '', key: 'paymentUpload.receiptType.enrollmentTransport' },
    { id: ReceiptType.Partial, name: '', key: 'paymentUpload.receiptType.partial' }
  ];

  public booleanTypes = [
    { id: BooleanType.Yes, key: 'general.yes', name: '' },
    { id: BooleanType.No, key: 'general.no', name: '' }
  ];

  public nameRegex = '^[a-zA-Z\\sàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸ]+$';
  public photoTypes = ['image/jpeg', 'image/png', 'image/bmp'];
  public fileTypes = this.photoTypes.concat(['application/pdf']);
  public mailTypes = this.fileTypes.concat([
    'text/plain',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow'
  ]);
  public photoMaxSize = 4;
  public fileMaxSize = 10;
  public linkGeneratorFileMaxSize = 20;

  public readonly defaultPhoto = {
    student: '/assets/img/bennett/student-photo-&.png',
    attendant: '/assets/img/bennett/attendant-photo-&.png',
    default: '/assets/img/bennett/default-photo.png',
    place: '/assets/img/bennett/place.png',
    discount: '/assets/img/bennett/discount.png',
    tutoring: '/assets/img/bennett/default-tutoring.png'
  };

  constructor(private translate: TranslateService, private notify: NotifyMessageService, private permissionsService: NgxPermissionsService) {
    const opts: any = {
      durationLabelsStandard: {
        S: 'millisegundo',
        SS: 'millisegundos',
        s: 'segundo',
        ss: 'segundos',
        m: 'minuto',
        mm: 'minutos',
        h: 'hora',
        hh: 'horas',
        d: 'día',
        dd: 'días',
        w: 'semana',
        ww: 'semanas',
        M: 'mes',
        MM: 'meses',
        y: 'año',
        yy: 'años'
      },
      longDateFormat: {
        LT: 'h:mm A',
        LTS: 'h:mm:ss A',
        L: 'DD/MM/YYYY',
        l: 'D/M/YYYY',
        LL: 'D [de] MMMM, YYYY',
        ll: 'D [de] MMM YYYY',
        LLL: 'D [de] MMMM, YYYY LT',
        lll: 'D [de] MMM YYYY LT',
        LLLL: 'dddd, LLL',
        llll: 'ddd lll'
      },
      calendar: {
        lastDay: '[ayer a las] h:mm A',
        sameDay: '[hoy a las] h:mm A',
        nextDay: '[mañana a las] h:mm A',
        lastWeek: '[el] dddd [pasado a las] h:mm A',
        nextWeek: 'dddd [a las] h:mm A',
        sameElse: 'LLL'
      }
    };

    moment.updateLocale('es', opts);

    moment.updateLocale('en', {
      calendar: {
        sameElse: 'LLL'
      }
    });

    const lists = {
      ruleTypes: this.ruleTypes,
      months: this.months,
      receiptDeductionsTypes: this.receiptDeductionsTypes,
      receiptTypes: this.receiptTypes,
      booleanTypes: this.booleanTypes
    };

    const refreshDaysTranslation = () => {
      this.days.forEach(item => {
        item.name = translate.instant(item.key);
        item.initial = item.name.substr(0, 1);
      });
    };

    refreshDaysTranslation();
    this.refreshListsTranslation(lists, this);
    moment.locale(this.translate.currentLang);

    translate.onLangChange.subscribe(() => {
      refreshDaysTranslation();
      this.refreshListsTranslation(lists, this);
      moment.locale(this.translate.currentLang);
    });
  }

  public handleListTranslation(lists, context) {
    this.refreshListsTranslation(lists, context);
    this.translate.onLangChange.subscribe(() => this.refreshListsTranslation(lists, context));
  }

  public refreshListsTranslation(lists, context) {
    Object.keys(lists).forEach(key => {
      const list = context[key];
      if (!list.length) return;
      const tran = this.translate.instant(list.map(s => s.key));
      Object.keys(tran).forEach((key2, i) => (list[i].name = tran[key2]));
      context[key] = [...context[key]];
    });
  }

  public handleLanguage(lang: string) {
    this.translate.get(['general.dtTotal', 'general.dtEmpty']).subscribe(res => {
      this.dtMessages.emptyMessage = res['general.dtEmpty'];
      this.dtMessages.totalMessage = res['general.dtTotal'];
    });
  }

  public refreshListPermissions(array, callback) {
    this.permissionsService.permissions$.subscribe(permission => {
      callback([...array.filter(item => (item.permission ? permission[item.permission] != null : true))]);
    });
  }

  getRuleType(id: RuleType) {
    return this.ruleTypes.find(rt => rt.id === id);
  }

  getMonth(id: Month) {
    return this.months.find(m => m.id === id);
  }

  getReceiptDeductionType(id: ReceiptDeductionType) {
    return this.receiptDeductionsTypes.find(rd => rd.id === id);
  }

  /**
   * Retorna un array solo con los valores de las filas que cumplan la validacion booleana verdadera
   * @param array Contenido a comparar
   * @param column Columna que contiene el valor a retornar
   * @param checkColumn Columna que contiene el valor a comparar
   */
  arrayCheck(array: any[], column: string, checkColumn: string) {
    const temp: any[] = [];
    array.forEach(item => {
      if (item[checkColumn] === true) temp.push(item[column]);
    });
    return temp;
  }

  /**
   * Retorna la diferencia de dos arrays (agregados y eliminados)
   * @param initial Array inicial
   * @param final Array final
   * @deprecated use `Array.difference` instead
   */
  arrayDifference(initial: any[], final: any[]) {
    initial = initial || [];
    final = final || [];

    return {
      added: final.filter(item => {
        return !initial.includes(item);
      }),
      removed: initial.filter(item => {
        return !final.includes(item);
      })
    };
  }

  randomId() {
    return '_' + Math.random().toString(36).substr(2, 9);
  }

  lightenColor(color: string, percent: number) {
    const num = parseInt(color, 16),
      amt = Math.round(2.55 * percent),
      R = (num >> 16) + amt,
      B = ((num >> 8) & 0x00ff) + amt,
      G = (num & 0x0000ff) + amt;

    return (0x1000000 + (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 + (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 + (G < 255 ? (G < 1 ? 0 : G) : 255))
      .toString(16)
      .slice(1);
  }

  objectToQueryParams(data: any) {
    const params: string[] = [];
    Object.keys(data).forEach(key => {
      if (data[key]) params.push(key + '=' + data[key]);
    });
    return params.join('&');
  }

  queryParamsToObject(queryParams: string) {
    return JSON.parse('{"' + queryParams.replace(/&/g, '","').replace(/=/g, '":"') + '"}', (key, value) => {
      return key === '' ? value : decodeURIComponent(value);
    });
  }

  /**
   * Calcula la edad basado en una fecha dada
   * @param d fecha en formato ISO
   */
  calculateAge(d: string): number {
    const date = moment(d, 'YYYY-MM-DD').toDate();
    const today = new Date();
    let age = today.getFullYear() - date.getFullYear();
    const m = today.getMonth() - date.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < date.getDate())) age--;
    return age < 0 ? null : age;
  }

  /**
   * Calcula la edad en meses de una fecha dada
   * @param d fecha en formato ISO
   */
  calculateAgeMonths(d: string): number {
    let months: number;
    const date = moment(d, 'YYYY-MM-DD').toDate();
    const today = new Date();
    months = (today.getFullYear() - date.getFullYear()) * 12;
    months -= date.getMonth() + 1;
    months += today.getMonth();
    return months <= 0 ? 0 : months;
  }

  formatNumber(x: number): string {
    const number = x.toString().replace('.', ',');
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }

  dateToPickerTime(date: string, format: string = null): NgbTimeStruct {
    if (!date) return null;
    const momentDate = moment(date, format);
    return {
      hour: momentDate.hour(),
      minute: momentDate.minute(),
      second: momentDate.second()
    };
  }

  timePickerToDate(date) {
    if (!date) return null;
    return moment().hour(date.hour).minute(date.minute).format('HH:mm');
  }

  /**
   * Gets the base64 from a input file
   * @param e Event from input File
   * @param validTypes valid mime types for validation
   * @param maxSize maximum MB size for validation
   * @param onLoad function to be called when the File has been finished the encoding to base64
   */
  base64FileInput(e, validTypes: string[], maxSize: number, onLoad: (file: IFileData) => void) {
    if (!e.target.files.length) return;

    for (const file of e.target.files) {
      if (!validTypes.includes(file.type)) {
        this.notify.error('validation.msgTitle', 'validation.invalidFileType');
        return;
      } else if (file.size > this.megaBytesToBytes(maxSize)) {
        this.notify.error('validation.msgTitle', { key: 'validation.invalidFileSize', params: { value: maxSize } });
        return;
      }
    }

    for (const file of e.target.files) {
      this.base64Blob(file, onLoad);
    }

    e.target.value = null;
  }

  private base64Blob(file: File, onLoad: (file: IFileData) => void) {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      onLoad.call(this, {
        name: file.name,
        mime: file.type,
        size: file.size,
        ext: file.name.includes('.') ? file.name.split('.').pop() : null,
        base64: reader.result
      });
    });
    reader.readAsDataURL(file);
  }

  megaBytesToBytes(mb: number) {
    return mb * 1024 * 1024;
  }

  randomUrlKey() {
    return '?r=' + new Date().getTime();
  }

  /**
   * Convert string date to NgbDateStruct
   * @param date string
   * @returns NgbDateStruct
   */
  dateToPickerDate(date?: string, format?: string): NgbDateStruct {
    let m: any;
    if (date) {
      m = moment(date, format || 'YYYY-MM-DD');
    } else {
      m = moment();
    }
    return {
      year: m.year(),
      month: m.month() + 1,
      day: m.date()
    };
  }

  /**
   * Convert moment to NgbDateStruct
   * @param date moment.Moment
   * @returns NgbDateStruct
   */
  momentToNgbDateStruct(date: moment.Moment): NgbDateStruct {
    return {
      year: date.year(),
      month: date.month() + 1,
      day: date.date()
    };
  }

  ngbDateStructToMoment(date: NgbDateStruct): moment.Moment {
    return moment()
      .year(date.year)
      .month(date.month - 1)
      .date(date.day)
      .startOf('day');
  }

  /**
   * Add headers to html deleting link reference (a)
   * @param body string
   */
  removeHtmlLinks(body: string) {
    return `<!doctype html><html><head><style>a { pointer-events: none; cursor: default; }</style></head><body>${body}</body></html>`;
  }

  /**
   * Open PDF in new tab from base64 encoded string
   * @param encodedFile string base64
   */
  viewPDF(encodedFile: string) {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      const blob = this.generateBlob(encodedFile, 'application/pdf');
      window.navigator.msSaveOrOpenBlob(blob);
    } else {
      const url = this.generateBlobUrl(encodedFile, 'application/pdf');
      window.open(url, '_blank');
    }
  }

  /**
   * Get default image string
   * @param gender string [M, F, NA]
   * @param type ImageEnum
   */
  getDefaultImageString(gender: string, type: ImageEnum): string {
    if (type === ImageEnum.Place) {
      return this.defaultPhoto.place;
    } else if (gender === null || gender === 'NA') {
      return this.defaultPhoto.default;
    } else if (type === ImageEnum.Attendant) {
      return this.defaultPhoto.attendant.replace('&', gender);
    } else if (type === ImageEnum.Student) {
      return this.defaultPhoto.student.replace('&', gender);
    } else {
      return this.defaultPhoto.default;
    }
  }

  /**
   * Generate blob URL to BASE64 file
   * @param base64File string
   * @param mime string
   * @param returnBlob boolean
   */
  generateBlobUrl(base64File: string, mime: string, returnBlob?: boolean) {
    const blob = this.generateBlob(base64File, mime);
    return URL.createObjectURL(blob);
  }

  /**
   * Generate blob URL to BASE64 file
   * @param base64File string
   * @param mime string
   */
  generateBlob(base64File: string, mime: string) {
    const byteCharacters = atob(base64File);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mime });
  }

  getDayNameKey(id: number) {
    switch (id) {
      case 1:
        return 'days.monday';
      case 2:
        return 'days.tuesday';
      case 3:
        return 'days.wednesday';
      case 4:
        return 'days.thursday';
      case 5:
        return 'days.friday';
      case 6:
        return 'days.saturday';
      case 7:
        return 'days.sunday';
    }
  }

  /**
   * Download file
   * @param string encodedFile
   * @param string mime
   * @param string ext
   * @param string section
   */
  download(encodedFile: string, mime: string, ext: string, fileName?: string) {
    const decodedFileData = atob(encodedFile);
    const byteNumbers = new Array(decodedFileData.length);
    for (let i = 0; i < decodedFileData.length; i++) {
      byteNumbers[i] = decodedFileData.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: mime });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    a.download = (fileName || moment().format('YYYY-MM-DD')) + '.' + ext;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  /**
   * Quitar los acentos de las vocales
   */
  removeAccents(s: string) {
    if (!s) return s;
    s = s.toLocaleLowerCase();
    return s
      .replace(new RegExp(/[àáâãäå]/g), 'a')
      .replace(new RegExp(/[èéêë]/g), 'e')
      .replace(new RegExp(/[ìíîï]/g), 'i')
      .replace(new RegExp(/[òóôõö]/g), 'o')
      .replace(new RegExp(/[ùúûü]/g), 'u');
  }

  simulateControlValueChange(control: AbstractControl) {
    const temp = control.value;
    control.setValue(null);
    control.setValue(temp);
  }

  /**
   * Genera color segun porcentaje 0-1 => red-green
   */
  getColorForPercentage(pct: number) {
    const percentColors = [
      { pct: 0.0, color: { r: 0xff, g: 0x00, b: 0 } },
      { pct: 0.5, color: { r: 0xff, g: 0xff, b: 0 } },
      { pct: 1.0, color: { r: 0x00, g: 0xff, b: 0 } }
    ];

    let i;
    for (i = 1; i < percentColors.length - 1; i++) {
      if (pct < percentColors[i].pct) {
        break;
      }
    }
    const lower = percentColors[i - 1];
    const upper = percentColors[i];
    const range = upper.pct - lower.pct;
    const rangePct = (pct - lower.pct) / range;
    const pctLower = 1 - rangePct;
    const pctUpper = rangePct;
    const color = {
      r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
      g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
      b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
    };
    return 'rgb(' + [color.r, color.g, color.b].join(',') + ')';
  }
}
