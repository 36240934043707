import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FunctionsService, HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

export enum PaymentReportType {
  Interface = 1,
  PaymentsPerSection = 2,
  PaymentsPerBank = 3,
  PaymentsRelation = 4
}

@Injectable()
export class PaymentService {
  public static URL = environment.URL.API + 'payment';
  public static URL2 = environment.URL.API2 + 'payment';

  reportTypes = [
    { id: PaymentReportType.Interface, key: 'portfolioConfiguration.paymentsInterface', name: '', permission: 'accounting_interface' },
    { id: PaymentReportType.PaymentsPerSection, key: 'payment.paymentsPerSection', name: '' },
    { id: PaymentReportType.PaymentsPerBank, key: 'payment.paymentsPerBank', name: '' },
    { id: PaymentReportType.PaymentsRelation, key: 'payment.paymentsRelation', name: '' }
  ];

  constructor(private http: HttpService, fn: FunctionsService, translate: TranslateService) {
    fn.refreshListPermissions(this.reportTypes, array => (this.reportTypes = array));

    const lists = { reportTypes: this.reportTypes };

    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  /**
   * List payments
   *
   * @param params string
   * @param httpRequestHandler HttpRequestHandler
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PaymentService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Detalle de pago
   */
  details(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PaymentService.URL}/${id}/details`, httpRequestHandler);
  }

  /**
   * Detalle de afectacion de pago en recibo
   */
  receiptDetails(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PaymentService.URL}/${id}/receipt-details`, httpRequestHandler);
  }

  /**
   * Download reports
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  downloadReport(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${PaymentService.URL}/reports`, data, httpRequestHandler);
  }

  /**
   * Verificar acciones a tomar con pago desconocido
   */
  checkUnknown(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${PaymentService.URL}/check-unknown`, data, httpRequestHandler);
  }

  /**
   * Aplicar pago desconocido a tercero
   */
  applyUnknown(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${PaymentService.URL}/apply-unknown`, data, httpRequestHandler);
  }

  /**
   * Check external payment
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  checkExternal(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PaymentService.URL}/${id}/check-external`, httpRequestHandler);
  }

  /**
   * Apply external payment
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  applyExternal(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${PaymentService.URL}/apply-external`, data, httpRequestHandler);
  }

  /**
   * Delete payment
   * @param id number
   * @param httpRequestHandler HttpRequestHandler
   */
  deletePayment(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${PaymentService.URL2}/${id}`, httpRequestHandler);
  }
  PaymentDeleted(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${PaymentService.URL2}?${params}`, httpRequestHandler);
  }
}
