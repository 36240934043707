import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class ExtracurricularClassService {
  public static URL = environment.URL.API2 + 'extracurricular-class';

  constructor(private http: HttpService) {
  }

  /**
   * List Extracurricular Classes
   *
   * @param params string
   * @param httpRequestHandler HttpRequestHandler
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ExtracurricularClassService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Get Extracurricular Class
   */
  get(yearId: number, id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ExtracurricularClassService.URL}/${yearId}/${id}`, httpRequestHandler);
  }

  /**
   * Save Extracurricular Class
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(ExtracurricularClassService.URL, data, httpRequestHandler);
  }

  /**
   * Delete Extracurricular Class
   */
  delete(yearId: number, extracurricularTeacherId: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${ExtracurricularClassService.URL}/${yearId}/${extracurricularTeacherId}`, httpRequestHandler);
  }

  /**
   * List Extracurricular Classes for select component
   */
  list = () => {
    return this.http.observableGet(`${ExtracurricularClassService.URL}/list`, { mapFn: res => res.classes });
  };

  /**
   * List Extracurricular Classes from year
   */
  fromYear = data => {
    return this.http.observableGet(`${ExtracurricularClassService.URL}/list/year/${data.yearId}`, { mapFn: res => res.classes });
  };
}
