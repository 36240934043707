import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {FunctionsService, HttpRequestHandler, HttpService} from 'app/services/util';
import {environment} from 'environments/environment';

export enum ContractStatus {
  Created = 1,
  Active = 2,
  Finalized = 3,
}

export enum ContractFormFrom {
  Admin = 1,
  Employee = 2,
}

export enum ActiveBigpass {
  Yes = 1,
  No = 2,
}

export enum ContractReportType {
  General = 1,
  /* MedicalInformation = 2,
   PersonalEmails = 3,
   EmergencyContacts = 4,*/
}

@Injectable()
export class ContractService {
  public static URL = environment.URL.API2 + 'contract';

  contractsStatus = [
    {id: ContractStatus.Created, key: 'contract.status.created', name: ''},
    {id: ContractStatus.Active, key: 'contract.status.active', name: ''},
    {id: ContractStatus.Finalized, key: 'contract.status.finalized', name: ''}
  ];

  contractsReportTypes = [
    {id: ContractReportType.General, key: 'general.general', name: '', permission: 'contract_consultation'}
  ];

  list = {
    activeBigpass: [
      {id: ActiveBigpass.Yes, name: '', key: 'contract.activeBigpass.yes', inForm: true, class: 'label label-warning'},
      {id: ActiveBigpass.No, name: '', key: 'contract.activeBigpass.no', inForm: true, class: 'label label-success'}
    ],
    revisedVouchers: [
      {id: ActiveBigpass.Yes, name: '', key: 'contract.activeBigpass.yes', inForm: true, class: 'label label-warning'},
      {id: ActiveBigpass.No, name: '', key: 'contract.activeBigpass.no', inForm: true, class: 'label label-success'}
    ]
  };

  constructor(private http: HttpService, translate: TranslateService, fn: FunctionsService) {
    const udpateLangValues = () => {
      this.list.activeBigpass.forEach(item => (item.name = translate.instant(item.key)));
      this.list.revisedVouchers.forEach(item => (item.name = translate.instant(item.key)));
      this.contractsReportTypes.forEach(item => (item.name = translate.instant(item.key)));

      this.contractsStatus.forEach(item => (item.name = translate.instant(item.key)));
      this.contractsStatus = [...this.contractsStatus];
    };
    udpateLangValues();
    translate.onLangChange.subscribe(value => udpateLangValues());
  }

  /**
   * Index contracts
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ContractService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Get empoyee data
   */
  getEmployeeData(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ContractService.URL}/employee/${id}`, httpRequestHandler);
  }

  /**
   * Get last contract employee
   */
  getLastContract(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ContractService.URL}/last-contract/${id}`, httpRequestHandler);
  }

  /**
   * Get contract
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${ContractService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save contract
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    return this.http.post(ContractService.URL, data, httpRequestHandler);
  }

  /**
   * Delete contract
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${ContractService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Export Report
   */

  /*export(data: any, httpRequestHandler: HttpRequestHandler) {
    return this.http.post(`${EmployeeService.URL}/export/`, data, httpRequestHandler);
  }*/
  export(httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${ContractService.URL}/export/`, httpRequestHandler);
  }

  print(id: number, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${ContractService.URL}/print/${id}`, httpRequestHandler);
  }

}
