import { Injectable } from '@angular/core';
import { HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

@Injectable()
export class ExtracurricularGroupService {
  public static URL = environment.URL.API2 + 'extracurricular-group';

  constructor(private http: HttpService) {
  }

  /**
   * List Extracurricular Activities
   *
   * @param params string
   * @param httpRequestHandler HttpRequestHandler
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ExtracurricularGroupService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Get Extracurricular Activity
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ExtracurricularGroupService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save Extracurricular Activity
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(ExtracurricularGroupService.URL, data, httpRequestHandler);
  }

  /**
   * Delete Extracurricular Activity
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${ExtracurricularGroupService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Listados de Estudiantes asociados y NO asociados a un grupo.
   */
  students(id: number, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${ExtracurricularGroupService.URL}/students/${id}`, httpRequestHandler);
  }

  /**
   * Asociar un estududiante al grupo.
   */
  addStudent(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${ExtracurricularGroupService.URL}/add-student`, data, httpRequestHandler);
  }

  /**
   * Desasociar un Estududiante del grupo.
   */
  removeStudent(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${ExtracurricularGroupService.URL}/remove-student/${id}`, httpRequestHandler);
  }

  /**
   * List Extracurricular Groups for select component
   */
  list = () => {
    return this.http.observableGet(`${ExtracurricularGroupService.URL}/list`, { mapFn: res => res.extracurricularGroups });
  };

  /**
   * List Extracurricular Group from year
   */
  fromYear = ({ yearId }) => {
    return this.http.observableGet(`${ExtracurricularGroupService.URL}/list/year/${yearId}`, { mapFn: res => res.extracurricularGroups });
  };
}
