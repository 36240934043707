import { Injectable } from '@angular/core';
import { FunctionsService, HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';
import { AttendanceReportType } from '../academic';
import { TranslateService } from '@ngx-translate/core';

export enum ExtracurricularTeacherAttendanceReportType {
  Diary = 1,
  RelationByExtracurricularTeacher = 2,
  ExtracurricularTeacherAttendance = 3,
  RelationByExtracurricularTeacherDetailed = 4,
}

@Injectable()
export class ExtracurricularTeacherAttendanceService {
  public static URL = environment.URL.API2 + 'extracurricular-teacher-attendance';

  reportTypes = [
    { id: ExtracurricularTeacherAttendanceReportType.Diary, key: 'extracurricularTeacherAttendance.reports.diary', name: '' },
    {
      id: ExtracurricularTeacherAttendanceReportType.RelationByExtracurricularTeacher,
      key: 'extracurricularTeacherAttendance.reports.relationByExtracurricularTeacher',
      name: ''
    },
    {
      id: ExtracurricularTeacherAttendanceReportType.ExtracurricularTeacherAttendance,
      key: 'extracurricularTeacherAttendance.reports.extracurricularTeacherAttendance',
      name: ''
    },
    {
      id: ExtracurricularTeacherAttendanceReportType.RelationByExtracurricularTeacherDetailed,
      key: 'extracurricularTeacherAttendance.reports.relationByExtracurricularTeacherDetailed',
      name: ''
    }
  ];

  constructor(private http: HttpService, translate: TranslateService, fn: FunctionsService) {
    const lists = { reportTypes: this.reportTypes };

    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  /**
   * List Extracurricular Activities
   *
   * @param params string
   * @param httpRequestHandler HttpRequestHandler
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ExtracurricularTeacherAttendanceService.URL}?${params}`, httpRequestHandler);
  }

  /**
   * Get Extracurricular Activity
   */
  get(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${ExtracurricularTeacherAttendanceService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save Extracurricular Activity
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(ExtracurricularTeacherAttendanceService.URL, data, httpRequestHandler);
  }

  /**
   * Delete Extracurricular Activity
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${ExtracurricularTeacherAttendanceService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Save observation
   */
  saveObservation(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${ExtracurricularTeacherAttendanceService.URL}/observation`, data, httpRequestHandler);
  }

  /**
   * Download interface
   * @param data any
   * @param httpRequestHandler HttpRequestHandler
   */
  export(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${ExtracurricularTeacherAttendanceService.URL}/export`, data, httpRequestHandler);
  }

  /**
   * List Extracurricular Activities for select component
   */
  list = () => {
    return this.http.observableGet(`${ExtracurricularTeacherAttendanceService.URL}/list`, { mapFn: res => res.activities });
  };

  /**
   * List Extracurricular Activities from year
   */
  fromYear = data => {
    return this.http.observableGet(`${ExtracurricularTeacherAttendanceService.URL}/list/year/${data.yearId}`, { mapFn: res => res.activities });
  };
}
