import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FunctionsService, HttpRequestHandler, HttpService } from 'app/services/util';
import { environment } from 'environments/environment';

export enum BillingStatus {
  Temp = 1,
  Billed = 2,
  Notified = 3,
  Issued = 4,
}

export enum BillingTempChangeType {
  Total = 1,
  New = 2,
  Remove = 3,
}

@Injectable()
export class BillingService {
  public static URL = environment.URL.API + 'billing';
  public static URL2 = environment.URL.API2 + 'billing';

  statuses = [
    { id: BillingStatus.Temp, key: 'billing.status.temp', name: '', class: 'label label-default' },
    { id: BillingStatus.Billed, key: 'billing.status.billed', name: '', class: 'label label-primary' },
    { id: BillingStatus.Notified, key: 'billing.status.notified', name: '', class: 'label label-success' },
    { id: BillingStatus.Issued, key: 'billing.status.issued', name: '', class: 'label bg-purple' }
  ];

  changeTypes = [
    { id: BillingTempChangeType.Total, key: 'billing.changeType.total', name: '', class: 'primary' },
    { id: BillingTempChangeType.New, key: 'billing.changeType.new', name: '', class: 'success' },
    { id: BillingTempChangeType.Remove, key: 'billing.changeType.remove', name: '', class: 'danger' }
  ];

  constructor(private http: HttpService, translate: TranslateService, fn: FunctionsService) {
    const lists = {
      statuses: this.statuses,
      changeTypes: this.changeTypes
    };

    fn.refreshListsTranslation(lists, this);
    translate.onLangChange.subscribe(() => fn.refreshListsTranslation(lists, this));
  }

  /**
   * Listado paginador de facturaciones
   */
  index(params: string, httpRequestHandler: HttpRequestHandler) {
    // this.http.get(`${BillingService.URL}?${params}`, httpRequestHandler);
    this.http.get(`${BillingService.URL2}?${params}`, httpRequestHandler);
  }

  /**
   * Guardar facturacion
   */
  save(data: any, httpRequestHandler: HttpRequestHandler) {
    this.http.post(BillingService.URL, data, httpRequestHandler);
  }

  /**
   * Obtener datos de facturacion con paginador
   */
  get(id: number, params: string, httpRequestHandler: HttpRequestHandler) {
    return this.http.get(`${BillingService.URL}/${id}?${params}`, httpRequestHandler);
  }

  /**
   * Datos de factura temporal* Obtener datos de facturacion con paginador
   */
  getTemp(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${BillingService.URL}/temp/${id}`, httpRequestHandler);
  }

  /**
   * Generar facturas reales
   */
  generate(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${BillingService.URL}/${id}/generate`, null, httpRequestHandler);
  }

  /**
   * Exportar listado de facturas temporales/generadas
   */
  export(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${BillingService.URL}/${id}/export`, httpRequestHandler);
  }

  /**
   * Eliminar facturación
   */
  delete(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${BillingService.URL}/${id}`, httpRequestHandler);
  }

  /**
   * Notificar facturación a acudientes
   */
  notify(id: number, opts: HttpRequestHandler) {
    return this.http.post(`${BillingService.URL}/${id}/notify`, null, opts);
  }

  /**
   * Emitir facturación electronica
   */
  issue(id: number, opts: HttpRequestHandler) {
    return this.http.post(`${BillingService.URL}/${id}/issue`, null, opts);
  }

  /**
   * Marcar factura como notificada
   */
  markAsNotified(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.post(`${BillingService.URL}/${id}/mark-as-notified`, null, httpRequestHandler);
  }

  /**
   * Listar cobros pendientes por facturar
   */
  listOutstanding(params: string, httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${BillingService.URL}/list-outstanding?${params}`, httpRequestHandler);
  }

  deleteBillingTemps(httpRequestHandler: HttpRequestHandler) {
    this.http.get(`${BillingService.URL2}/delete-billing-temps`, httpRequestHandler);
  }

  deleteInterest(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${BillingService.URL2}/${id}/interest`, httpRequestHandler);
  }

  deleteOutstandingBalance(id: number, httpRequestHandler: HttpRequestHandler) {
    this.http.delete(`${BillingService.URL2}/delete-outstanding-balance/${id}`, httpRequestHandler);
  }
}
